<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table">
        <div class="card-body">
          <div class="py-6" v-if="loading">
            <table-skeleton> </table-skeleton>
          </div>
          <DataTable
            v-else
            :columns="[
              { field: 'first_name', label: 'First Name' },
              { field: 'last_name', label: 'Last Name' },
              {
                field: 'email',
                label: 'email',
              },
              {
                field: 'date_joined',
                label: 'Added',
                formatFn: createdFormatter,
              },
              { field: 'view', label: 'Action', sortable: false },
            ]"
            :rows="users"
            :totalRecords="totalUsers"
            :basePaginatorUrl="baseUserUrl"
            detailsRouteName="user-details"
            @fetch-next-page-results="getUsers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";

export default {
  name: "PartnerAdminsTable",
  components: { DataTable },
  data() {
    return {
      users: [],
      totalUsers: null,
      baseUserUrl: null,
      error: "",
      searchQuery: null,
      loading: true,
    };
  },
  computed: {
    filteredList() {
      return this.users.filter((user) => {
        return user.first_name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(
      clearUsers,
      baseUserUrl = "v1/auth/users/?role=ared_admin&is_active=true"
    ) {
      if (clearUsers) {
        this.users = [];
      }
      this.baseUserUrl = baseUserUrl;
      axios
        .get(baseUserUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.users = response.data.results;
            this.totalUsers = response.data.count;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    roleFormatter(value) {
      return value ? `Ared Admin` : `Staff`;
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
  },
};
</script>
<style></style>
