<template>
  <form @submit.prevent="handleSubmit">
    <div class="">
      <div class="input-group mb-4">
        <input
          type="text"
          class="form-control"
          v-model="first_name"
          placeholder="Enter First Name"
          required
        />
        <small v-if="responseErrors.first_name" class="text-danger help-text">
          {{ responseErrors.first_name[0] }}
        </small>
      </div>
      <div class="input-group mb-4">
        <input
          type="text"
          class="form-control"
          v-model="last_name"
          placeholder="Enter Last Name"
          required
        />
        <small v-if="responseErrors.last_name" class="text-danger help-text">
          {{ responseErrors.last_name[0] }}
        </small>
      </div>
      <div class="mb-4">
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            v-model="email"
            placeholder="Enter Email"
            required
          />
        </div>
        <small v-if="responseErrors.email" class="text-danger help-text">
          {{ responseErrors.email[0] }}
        </small>
      </div>
      <div class="input-group mb-4">
        <vue-tel-input
          v-model="phone"
          placeholder="Enter Phone Number"
          class="w-100"
          v-bind="bindProps"
          required
        ></vue-tel-input>
        <small v-if="responseErrors.phone" class="text-danger help-text">
          {{ responseErrors.phone[0] }}
        </small>
      </div>
      <div class="input-group mb-4">
        <select type="text" class="form-control" v-model="gender" required>
          <option value="">Select Gender</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="Other">Other</option>
        </select>
        <small v-if="responseErrors.gender" class="text-danger help-text">
          {{ responseErrors.gender[0] }}
        </small>
      </div>
      <!-- <div class="input-group mb-4">
        <input
          type="text"
          class="form-control"
          v-model="national_id_no"
          placeholder="National ID"
          required
        />
        <small v-if="responseErrors.national_id_no" class="text-danger help-text">
          {{ responseErrors.national_id_no[0] }}
        </small>
      </div> -->
      <div class="input-group mb-4">
        <select
          v-model="country"
          class="form-control"
          id="country"
          name="country"
          required
        >
          <option value="">Select Country</option>
          <option v-for="country in countries" :value="country" :key="country">
            {{ country }}
          </option>
        </select>
        <small v-if="responseErrors.country" class="text-danger help-text">
          {{ responseErrors.country[0] }}
        </small>
      </div>
      <div class="input-group mb-4">
        <select
          v-model="type"
          class="form-control"
          id="type"
          name="type"
          required
        >
          <option value="">Select User Type</option>
          <option
            v-for="userType in userTypes"
            :value="userType.value"
            :key="userType.value"
          >
            {{ userType.name }}
          </option>
        </select>
        <small v-if="responseErrors.type" class="text-danger help-text">
          {{ responseErrors.type[0] }}
        </small>
      </div>
    </div>
    <div class="text-center py-3">
      <button class="btn btn-info mr-2" @click="$emit('toggle-is-hidden')">
        Cancel
      </button>
      <button
        class="btn btn-space btn-primary"
        :disabled="loading"
        type="submit"
      >
        <span v-if="loading" class="spinner-border spinner-border-sm"></span>
        {{ submit }}
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import router from "@/router";

import { countries } from "../../data/countries";

export default {
  name: "AdminCreationForm",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      gender: "",
      type: "",
      country: "",
      selectedPermissions: [],
      loading: false,
      responseErrors: {},
      submit: "Add User",
      bindProps: {
        mode: "international",
      },
      userTypes: [
        {
          name: "Admin",
          value: "Admin",
        },
        {
          name: "Manufacturer",
          value: "Manufacturer",
        },
        {
          name: "Customer Support Agent",
          value: "Customer Support Agent",
        },
        {
          name: "Sales Representative",
          value: "Sales Representative",
        },
      ],
      countries: countries,
    };
  },
  methods: {
    getUserRole(userType) {
      switch (userType) {
        case "Admin":
          return "ared_admin";
        case "Manufacturer":
          return "Manufacturer";
        default:
          return "ared_staff";
      }
    },
    getValidUserType(userType) {
      switch (userType) {
        case "Admin":
          return "Regular User";
        case "Manufacturer":
          return "Regular User";
        default:
          return userType;
      }
    },

    async handleSubmit() {
      this.loading = true;
      this.submit = "Adding User";

      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        gender: this.gender,
        phone: this.phone.split(" ").join(""),
        user_type: this.getValidUserType(this.type),
        role: this.getUserRole(this.type),
      };

      await axios({
        url: `v1/auth/users/`,
        method: "POST",
        data: data,
      })
        .then((response) => {
          this.loading = false;
          if (response.status === 201) {
            router.push({
              name: "user-details",
              params: { id: response.data.id },
            });
            this.$swal("Success", "User added Successfully", "success");
          }
        })
        .catch((error) => {
          this.responseErrors = error.response.data;
          console.log("RESPONSE ERRORS: ", this.responseErrors);
          this.loading = false;
          this.submit = "Add User";
        });
    },
    handleSelectPermission(id, checked) {
      if (checked) {
        this.selectedPermissions.push(id);
      } else {
        this.selectedPermissions = this.selectedPermissions.filter(
          (permission) => permission !== id
        );
      }
    },
    handleSelectPermissionGroup(index, checked) {
      const selectedGroupIdList = this.permissions[index][1].map(
        (permission) => permission.id
      );
      if (checked) {
        this.selectedPermissions = [
          ...this.selectedPermissions,
          ...selectedGroupIdList,
        ];
      } else {
        this.selectedPermissions = this.selectedPermissions.filter(
          (permission) => !selectedGroupIdList.includes(permission)
        );
      }
    },
  },
  mounted() {
    // this.getCountries();
    // this.getAvailablePermissions();
  },
};
</script>
