<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'users' }"
              >System Admins</router-link-active
            >
          </li>
        </ol>
      </nav>
    </div>

    <div class="card">
      <!-- <div class="card-header">
        <ul class="nav nav-tabs nav-fill card-header-tabs">
          <li class="nav-item">
            <span
              :class="['nav-link', { active: activeTab === 'ared-admins' }]"
              @click="setActiveTab('ared-admins')"
            >
              Ared Admins
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="[
                'nav-link',
                'mr-1',
                { active: activeTab === 'manufacturers' },
              ]"
              href="#"
              @click="setActiveTab('manufacturers')"
            >
              Manufacturers
            </span>
          </li>
        </ul>
      </div> -->
      <div class="card-body">
        <UsersComponent />
      </div>
    </div>
  </div>
</template>
<script>
import UsersComponent from "@/components/Users/UsersComponent";

export default {
  components: { UsersComponent },
};
</script>
<style></style>
