<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Add Admin
            <div class="btn btn-primary float-right" @click="toggleIsHidden">
              Cancel
            </div>
          </div>
          <div class="card-body">
            <admin-creation-form @toggle-is-hidden="toggleIsHidden"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h3 class="mb-0">All Ared Admins</h3>
          <div>
            <button class="btn btn-primary" @click="toggleIsHidden">
              Add Admin
            </button>
          </div>
        </div>
        <admin-table/>
      </div>
    </div>
  </div>
</template>
<script>
import AdminCreationForm from "@/components/Users/CreationForm";
import AdminTable from "@/components/Users/Table";

export default {
  name: "PlanComponent",
  components: {AdminCreationForm, AdminTable},
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    toggleIsHidden() {
      this.isHidden = !this.isHidden;
    },
  },
};
</script>
<style></style>
